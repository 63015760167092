.react-datepicker__input-container > input {
  /* width: 100px; */
  width: inherit;
  height: 38px;
}

.react-datepicker-wrapper {
  display: flex;
  align-self: center;
}

.react-datepicker__input-container {
  width: 100%; /* Lățimea inițială */
  margin-left: 6px;
}
.react-datepicker-ignore-onclickoutside:focus-visible {
  outline: none;
}
.css-k008qs {
  justify-content: space-between;  
}

.css-1fszhz0:focus-visible {
  /* border-color: #e2e8f0;
  box-shadow: #e2e8f0; */
  border-color: #e2e8f0 !important;
  box-shadow: none !important;
}

/* Stilurile pentru .react-datepicker__input-container pentru ecrane cu lățimea mai mică de 320px */
@media (max-width: 320px) {
  .react-datepicker__input-container {
    width: 75px; /* Ajustați dimensiunea după preferințe */
  }
  .css-k008qs {
    gap: 0.3rem;
  }
  .react-datepicker__input-container > input {
    width: 100%;
  }
}

/* Stilurile pentru .react-datepicker__input-container pentru ecrane cu lățimea mai mică de 320px */
@media (max-width: 576px) {
  @media (min-width: 376px) {
    .react-datepicker-wrapper {
      width: 60%;
    }
  }
}
